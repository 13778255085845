import React from 'react';
import { Modal } from 'react-bootstrap';
import '../App.css';
import Charles from './../images/testimonials/charles.png';
import Dion from './../images/testimonials/Dion.png';
import Fabian from './../images/testimonials/fabian.png';
import files from './../images/homepageIcons/files.svg';
import application from './../images/homepageIcons/application.svg';
import customers from './../images/homepageIcons/customers.svg';
import tabOne from './../images/homePageTab/tabOne.svg';
import tabThree from './../images/homePageTab/tabThree.svg';
import tabTwo from './../images/homePageTab/tabTwo.svg';
import tabFour from './../images/homePageTab/tabFour.svg';
import eco_img from './../images/eco_img.png';
import icon_1 from './../images/icon_1.svg';
import icon_2 from './../images/icon_2.svg';
import icon_3 from './../images/icon_3.svg';
import icon_4 from './../images/icon_4.svg';
import icon_5 from './../images/icon_5.svg';
import icon_6 from './../images/icon_6.svg';
import icon_7 from './../images/icon_7.svg';
import icon_8 from './../images/icon_8.svg';
import icon_9 from './../images/icon_9.svg';
import icon_10 from './../images/icon_10.svg';
import arrowR from './../images/arrow-right.svg';
import arrowL from './../images/left-arrow.svg';
import { Footer } from './Layout/footer';
import { Header } from './Layout/header';
import phone from './../images/phone_icon.svg';
import mail from './../images/mail_icon.svg';
import tic from './../images/tic.svg';
import error from './../images/error (2).svg';
import emailjs from 'emailjs-com'
import CountUp from 'react-countup';
import Iframe from 'react-iframe'
import { config } from '../config/config'
import img01 from './../images/illustration/img01.png'

export class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ROI: 'flex',
      ECO: 'none',
      SAAS: 'none',
      Models: 'none',
      emailValidateshow: 'none',
      phoneNo: '',
      showsuceess: false,
      showError: false


    }
  }


  handleClickROI = () => {
    const oldClassName = document.getElementById('ROI').className;
    const newClassName = oldClassName === 'd-flex justify-content-center navTitle' ? 'd-flex justify-content-center navTitleClick' : 'd-flex justify-content-center navTitle'
    document.getElementById('ROI').className = newClassName
    document.getElementById('ECO').className = 'd-flex justify-content-center navTitle'
    document.getElementById('SAAS').className = 'd-flex justify-content-center navTitle'
    document.getElementById('Models').className = 'd-flex justify-content-center navTitle'
    this.setState({
      ROI: 'flex',
      ECO: 'none',
      SAAS: 'none',
      Models: 'none'
    })

  }
  handleClickECO = () => {
    const oldClassName = document.getElementById('ECO').className;
    const newClassName = oldClassName === 'd-flex justify-content-center navTitle' ? 'd-flex justify-content-center navTitleClick' : 'd-flex justify-content-center navTitle'
    document.getElementById('ECO').className = newClassName
    document.getElementById('ROI').className = 'd-flex justify-content-center navTitle'
    document.getElementById('SAAS').className = 'd-flex justify-content-center navTitle'
    document.getElementById('Models').className = 'd-flex justify-content-center navTitle'
    this.setState({
      ROI: 'none',
      ECO: 'flex',
      SAAS: 'none',
      Models: 'none'
    })
  }
  handleClickSAAS = () => {
    const oldClassName = document.getElementById('SAAS').className;
    const newClassName = oldClassName === 'd-flex justify-content-center navTitle' ? 'd-flex justify-content-center navTitleClick' : 'd-flex justify-content-center navTitle'
    document.getElementById('SAAS').className = newClassName
    document.getElementById('ECO').className = 'd-flex justify-content-center navTitle'
    document.getElementById('ROI').className = 'd-flex justify-content-center navTitle'
    document.getElementById('Models').className = 'd-flex justify-content-center navTitle'
    this.setState({
      ROI: 'none',
      ECO: 'none',
      SAAS: 'flex',
      Models: 'none'
    })
  }
  handleClickModels = () => {
    const oldClassName = document.getElementById('Models').className;
    const newClassName = oldClassName === 'd-flex justify-content-center navTitle' ? 'd-flex justify-content-center navTitleClick' : 'd-flex justify-content-center navTitle'
    document.getElementById('Models').className = newClassName
    document.getElementById('ECO').className = 'd-flex justify-content-center navTitle'
    document.getElementById('SAAS').className = 'd-flex justify-content-center navTitle'
    document.getElementById('ROI').className = 'd-flex justify-content-center navTitle'
    this.setState({
      ROI: 'none',
      ECO: 'none',
      SAAS: 'none',
      Models: 'flex'
    })
  }

  handleClose = () => {
    this.setState({
      show: false
    })
  }
  handleCloseSuccess = () => {
    this.setState({
      showsuceess: false
    })
  }
  handleCloseError = () => {
    this.setState({
      showError: false
    })
  }


  handleShow = () => {
    this.setState({
      show: true
    })
  }

  render() {
    const { email, phoneNo } = this.state;
    return (
      <div className="">
        <>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={this.state.show} onHide={this.handleClose}>
            <Modal.Body><div className="p-2">
              <div>
                <p onClick={this.handleClose} className="ModalCancel">Close</p>
                <Iframe url={config.url}
                  width="100%"
                  height="680px"
                  id="myId"
                  className="myClassname"
                  display="initial"
                  frameBorder="0"
                  position="relative" />

              </div>


            </div></Modal.Body>
          </Modal>
        </>
        {/* <>
          <Modal
            size="lg"
            aria-labelledby=""
            centered show={this.state.show} onHide={this.handleClose}>
            <Modal.Body><div className="p-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <img className="modelIcon" src={phone} alt="Logo" />
                    <p className="contactDetails">+61 7 3051 5815</p>
                  </div>
                  <div className="col-sm-4">
                    <img className="modelIcon" src={mail} alt="Logo" />
                    <p className="contactDetails">hello@ascensionit.com.au</p>
                  </div>
                  <div className="col-sm-4">
                    <img className="modelIcon" src={phone} alt="Logo" />
                    <p className="contactDetails">+94 115 927 640</p>
                  </div>
                </div>
              </div>
              <div className="container mt-2">
                <div className="row">
                  <p className="ModelTittle">We are happy to hear from you</p>
                  <p className="ModelsubTittle">Please fillout the form and get in touch with us</p>
                </div>
              </div>
              <div className="container">
                <form className="form" onSubmit={this.onSubmit}>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="fullname" className="" placeholder="Your full name" type="text" required />
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="email" id="email" value={email} onBlur={this.handleBlurEvent} placeholder="Your email address" type="text" required />
                      <div class="emailValidate" style={{ display: this.state.emailValidateshow }}>
                        Please input valid email ID
                      </div>
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="phone" id="phoneNo" value={phoneNo} onChange={this.onChange} placeholder="A number where we can reach out" type="tel" required />
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <textarea name="message" placeholder="The message you want us to read about" type="text" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 ">
                      <button type="submit" className="BtnSendMessage mt-5">Send Message </button>
                    </div>
                    <div className="col-6 ">
                      <p onClick={this.handleClose} className="CancelBtn">Cancel</p>
                    </div>

                  </div>
                </form>
              </div>
            </div></Modal.Body>
          </Modal>
        </> */}
        {/* <>
          <Modal show={this.state.showsuceess} onHide={this.handleCloseSuccess}>
            <Modal.Body>
              <div className="container p-4">
                <img className="modelIcon" src={tic} alt="Logo" />
                <p className="thankyou">Thank You!</p>
                <p className="thankyousub" >Your message was Successfully Delivered.</p>
                <button className="thankyouBtn" onClick={this.handleCloseSuccess}>
                  Continue
          </button>
              </div>
            </Modal.Body>
          </Modal>
        </> */}
        {/* <>
          <Modal show={this.state.showError} onHide={this.handleCloseError}>
            <Modal.Body>
              <div className="container p-4">
                <img className="modelIcon" src={error} alt="Logo" />
                <p className="thankyou">Sorry! Try again</p>
                <p className="thankyousub" >Your message couldn’t be Delivered.</p>
                <button className="thankyouBtn" onClick={this.handleCloseError}>
                  Continue
          </button>
              </div>
            </Modal.Body>
          </Modal>
        </> */}

        <div className="">
          <section id='' className=" container" data-anchor='firstPage'>
            <div className="col-12  head">
              <Header></Header>
            </div>
            <div className="row fixHeight">
              <div className="col-12 col-sm-6">
                <p className="TopText"><b>Digital</b> Transformation
                Simplified</p>
                <img id="MobileBanner" className="banner" src={img01} alt="Logo" />
                <p className="TopSubText">Empowering providers to win in the digital economy</p>
                <button type="button" onClick={this.handleShow} className="BtnGetInTouch">Get in touch &emsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
              </div>
              <div className="col col-sm-6">
                <img id="DesktopBanner" className="bannertop " src={img01} alt="Logo" />
              </div>
            </div>
            <div className="row works mt-sm-5  justify-content-md-center" >
              <div className="col mt-sm-3">
                <img className="iconImages " src={customers} alt="Logo" />
                <CountUp
                  className="customerNumber"
                  prefix="+"
                  end={30000}
                  separator="," />
                {/* <p className="customerNumber">+30,000</p> */}
                <p className="subText">End Customers</p>
              </div>
              <div className="col-sm mt-sm-3">
                <img className="iconImages" src={files} alt="Logo" />
                <CountUp
                  className="customerNumber"
                  start={0.0}
                  prefix="+"
                  suffix=" Million"
                  decimals={1}
                  decimal="."
                  separator=" "
                  end={4.5} />
                {/* <p className="customerNumber">+2.6 Million</p> */}
                <p className="subText">Managed Files</p>
              </div>
              <div className="col mt-sm-3">
                <img className="iconImages" src={application} alt="Logo" />
                <CountUp
                  className="customerNumber"
                  prefix="+"
                  end={3800}
                  separator=","
                />
                {/* <p className="customerNumber">+3,800</p> */}
                <p className="subText">Applications Deployed</p>
              </div>
            </div>
          </section>
          <section id='' className="container SecondSection" data-anchor='secondPage'>
            <div className="row" >
              <p className="col-12 featuresTittel">WINNING THE DIGITAL ECONOMY</p>
            </div>
            <div className="row">
              <p className="col-12 featuresSubTittel">With CloudShed&trade; Platform</p>
            </div>
            <div className="row mt-2 desktop flex" >
              <div className="col-3 ">
                <p id="ROI" className="d-flex justify-content-center navTitleClick" onClick={this.handleClickROI}>Fast TTM and Quick ROI</p>
              </div>
              <div className="col-3">
                <p id="ECO" className="d-flex justify-content-center navTitle" onClick={this.handleClickECO}>Build Vibrant Ecosystems</p>
              </div>
              <div className="col-3">
                <p id="SAAS" className="d-flex justify-content-center navTitle" onClick={this.handleClickSAAS}>Accelerate SaaSification</p>
              </div>
              <div className="col-3 pr-0">
                <p id="Models" className="d-flex justify-content-center navTitle" onClick={this.handleClickModels}>Adopt Multiple Business Models</p>
              </div>
              <div id="navbar" className="tabs tabs-style-underline">
              </div>
            </div>
            <div className="row mt-5 mb-5 desktop" style={{ display: this.state.ROI }}>
              <div className="col-sm-6">
                <p className="SaasTopic">Fast TTM and Quick ROI</p>
                <p className="SaasContent">In this ever competitive digital markets, if you are to be <strong>ahead of the competition</strong> and reap the benefit of
                being <strong>first-movers advantage</strong>, you cannot rely on your legacy and inflexible core systems.  <br /><br />
                CloudShed provides you a <strong>frictionless integrated platform</strong> to on board your digital soutions and <strong>goto market </strong>
                at a <strong>blazing fast</strong> rate. <br /><br />CloudShed will achieve your <strong>ROIs in few months.</strong></p>
              </div>
              <div className="col-sm-6 mt-sm-5 p-0">
                <img className="tabImage mt-sm-4" src={tabOne} alt="Logo" />
              </div>
            </div>
            <div className="row mt-5 mb-5 desktop" style={{ display: this.state.ECO }}>
              <div className="col-sm-6">
                <p className="SaasTopic">Build Vibrant Ecosystems</p>
                <p className="SaasContent">Key to success in the digital economy is having the best ecosystem of patners. You can build and manage a
                vibrant ecosystem of developers, resellers, service providers and customers to name a few.  <br /><br />
                  <strong>Build, Empower</strong> and <strong>Grow</strong> your partner network with ease and get them to become your partners in success.</p>
              </div>
              <div className="col-sm-6">
                <img className="tabImage" src={tabTwo} alt="Logo" />
              </div>
            </div>
            <div className="row mt-5 mb-5 desktop" style={{ display: this.state.SAAS }}>
              <div className="col-sm-6">
                <p className="SaasTopic">Accelerate SaaSification </p>
                <p className="SaasContent">Convert your software solution to a <strong>software-as-a-service</strong> with in a short period
                of time and expose to your wide range customers to reap the benefits with the <strong>pay-as-you-go</strong> model. <br /><br />
                When it comes to SaaSification, CloudShed is <strong>batteries included</strong>, we provide <strong>SSO, MFA, billing, collection,
                integration, orchestration</strong> to <strong>life cycle management</strong> of your newly converted SaaS product. </p>
              </div>
              <div className="col-sm-6">
                <img className="tabImage mt-sm-4" src={tabThree} alt="Logo" />
              </div>
            </div>
            <div className="row mt-5 mb-5 desktop" style={{ display: this.state.Models }}>
              <div className="col-sm-6">
                <p className="SaasTopic">Adopt Multiple Business Models</p>
                <p className="SaasContent">Every digital product has its' own <strong>unique value and selling proposition. </strong>
                 If you were to enforce one business model just because your legacy system cannot cater for different
                business models you would not be able to bring it's <strong>full potential</strong> to the customer. <br /><br />
                CloudShed platform <strong>enable and empowers</strong> you to apply different business models based on your
                unique offering that <strong>make sense</strong> to the customer. </p>
              </div>
              <div className="col-sm-6 mt-sm-3">
                <img className="tabImage mt-sm-4" src={tabFour} alt="Logo" />
              </div>
            </div>
            <div data-interval="false" id="carouselExampleControls" class="carousel slide mb-5 mobile " data-ride="carousel">
              <ol class="carousel-indicators mt-4">
                <a class="carousel-control-prev " href="#carouselExampleControls" role="button" data-slide="prev"><img className="caroselLeft" src={arrowL} alt="Logo" /></a>
                <a class="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next"><img className="caroselRight" src={arrowR} alt="Logo" /></a>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div>
                    <p id="ROIM" className="d-flex justify-content-center navTitleClick" >Fast TTM and Quick ROI</p>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <p className="SaasContent">In this ever competitive digital markets, if you are to be <strong>ahead of the competition</strong> and reap the benefit of
                being <strong>first-movers advantage</strong>, you cannot rely on your legacy and inflexible core systems.  <br /><br />
                CloudShed provides you a <strong>frictionless integrated platform</strong> to on board your digital soutions and <strong>goto market </strong>
                at a <strong>blazing fast</strong> rate. <br /><br />CloudShed will achieve your <strong>ROIs in few months.</strong> </p>
                  </div>
                  <div className="col-sm-6">
                    <img className="tabImage" src={tabOne} alt="Logo" />
                  </div>
                </div>
                <div class="carousel-item">
                  <div >
                    <p id="ECOM" className="d-flex justify-content-center navTitleClick" >Build Vibrant Ecosystems</p>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <p className="SaasContent">Key to success in the digital economy is having the best ecosystem of patners. You can build and manage a
                      vibrant ecosystem of developers, resellers, service providers and customers to name a few.  <br /><br />
                      <strong>Build, Empower</strong> and <strong>Grow</strong> your partner network with ease and get them to become your partners in success. </p>
                  </div>
                  <div className="col-sm-6">
                    <img className="tabImage" src={tabTwo} alt="Logo" />
                  </div>
                </div>
                <div class="carousel-item">
                  <div >
                    <p id="SAASM" className="d-flex justify-content-center navTitleClick" >SaaS-ification Acceleration</p>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <p className="SaasContent">Convert your software solution to a <strong>software-as-a-service</strong> with in a short period
                    of time and expose to your wide range customers to reap the benefits with the <strong>pay-as-you-go</strong> model. <br /><br />
                    When it comes to SaaSification, CloudShed is <strong>batteries included</strong>, we provide <strong>SSO, MFA, billing, collection,
                    integration, orchestration</strong> to <strong>life cycle management</strong> of your newly converted SaaS product.</p>
                  </div>
                  <div className="col-sm-6">
                    <img className="tabImage" src={tabThree} alt="Logo" />
                  </div>
                </div>
                <div class="carousel-item">
                  <div >
                    <p id="SAASM" className="d-flex justify-content-center navTitleClick" >Adopt Multiple Business Models</p>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <p className="SaasContent">Every digital product has its' own <strong>unique value and selling proposition. </strong>
                 If you were to enforce one business model just because your legacy system cannot cater for different
                business models you would not be able to bring it's <strong>full potential</strong> to the customer. <br /><br />
                CloudShed platform <strong>enable and empowers</strong> you to apply different business models based on your
                unique offering that <strong>make sense</strong> to the customer. </p>
                  </div>
                  <div className="col-sm-6">
                    <img className="tabImage" src={tabFour} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id='' className="container mt-sm-5" data-anchor='thiredPage'>
            <div className="row mt-sm-5 ">
              <p className="col-12 mt-sm-5 featuresTittel">KEY FEATURES</p>
            </div>
            <div className="row ">
              <p className="col-12 featuresSubTittel">To win the Digital Economy</p>
            </div>
            <div className="row desktop">
              <div className="container px-5 py-4">
                <div className="row">
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages icon1" src={icon_1} alt="Logo" />
                    </div>
                    <div>
                      <p>ONE DIGITAL ACCOUNT</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages " src={icon_2} alt="Logo" />
                    </div>
                    <div className="service">
                      <p>SERVICE</p>
                      <p>CATALOG</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages" src={icon_3} alt="Logo" />
                    </div>
                    <div>
                      <p>DIGITAL CUSTOMER JOURNEYS</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages" src={icon_4} alt="Logo" />
                    </div>
                    <div>
                      <p>FIELD SERVICE MANAGEMENT</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages " src={icon_5} alt="Logo" />
                    </div>
                    <div>
                      <p>PARTNER ECOSYSTEM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-5 desktop">
              <div className="container px-5 ">
                <div className="row">
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages " src={icon_6} alt="Logo" />
                    </div>
                    <div>
                      <p>INTERNET OF THINGS (IOT)</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages" src={icon_7} alt="Logo" />
                    </div>
                    <div>
                      <p>ROBOTIC PROCESS AUTOMATION (RPA)</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages" src={icon_8} alt="Logo" />
                    </div>
                    <div>
                      <p>SERVICE INTEGRATION</p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages" src={icon_9} alt="Logo" />
                    </div>
                    <div>
                      <p>SERVICE FULFILLMENT </p>
                    </div>
                  </div>
                  <div className="col mx-1 Rectangle">
                    <div className="icons">
                      <img className="iconImages icon1" src={icon_10} alt="Logo" />
                    </div>
                    <div>
                      <p>CLOUD SERVICE BROKERAGE</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="carouselExampleIndicators" data-interval="false" class="carousel slide container pl-4 pr-4 pt-4 mobile" data-ride="carousel">
              <div className="row featureText">
                <div className="col-12">
                  <div className="row p-2">
                    <div className="col-3">
                      <img className="featurePic" src={icon_1} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p> ONE DIGITAL ACCOUNT</p>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-3">
                      <img className="featurePic" src={icon_2} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p> SERVICE CATALOG</p>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-3">
                      <img className="featurePic" src={icon_3} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p>ENHANCING DIGITAL CUSTOMER JOURNEYS</p>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-3">
                      <img className="featurePic" src={icon_4} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p> FIELD SERVICE MANAGEMENT</p>
                    </div>
                  </div> */}
                  <div className="row p-2">
                    <div className="col-3">
                      <img className="featurePic" src={icon_5} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p>PARTNER ECOSYSTEM</p>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-3">
                      <img className="featurePic" src={icon_6} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p>INTERNET OF THINGS (IOT)</p>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-3">
                      <img className="featurePic" src={icon_8} alt="Logo" />
                    </div>
                    <div className="col-9">
                      <p>SERVICE INTEGRATION</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="carousel-inner ">
                <div class="carousel-item active ">
                  <div className="col-12  Rectangle">
                    <img className="iconImages" src={icon_1} alt="Logo" />
                    <p>ONE DIGITAL ACCOUNT</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages " src={icon_2} alt="Logo" />
                    <p>SERVICE CATALOG</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages" src={icon_3} alt="Logo" />
                    <p>ENHANCING DIGITAL CUSTOMER JOURNEYS</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col Rectangle">
                    <img className="iconImages" src={icon_4} alt="Logo" />
                    <p>FIELD SERVICE MANAGEMENT</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages " src={icon_5} alt="Logo" />
                    <p>PARTNER ECOSYSTEM</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages " src={icon_6} alt="Logo" />
                    <p>INTERNET OF THINGS (IOT)</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages" src={icon_7} alt="Logo" />
                    <p>ROBOTIC PROCESS AUTOMATION (RPA)</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages" src={icon_8} alt="Logo" />
                    <p>SERVICE INTEGRATION</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages" src={icon_9} alt="Logo" />
                    <p>SERVICE FULFILLMENT & ORCHESTRATION</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col  Rectangle">
                    <img className="iconImages" src={icon_10} alt="Logo" />
                    <p>HYBRID CLOUD / MULTI-CLOUD SERVICE BROKERAGE</p>
                  </div>
                </div>
              </div> */}
              {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" aria-hidden="false"><span style={{ color: "black" }} className="fa fa-angle-left leftArrow"></span></a>
              <ol class="carousel-indicators mt-4">

                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>

                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" aria-hidden="true"><i style={{ color: "black", marginLeft: "25px" }} className="fa fa-angle-right"></i></a>
              </ol> */}

              {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span style={{ color: "black" }} class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a> */}
            </div>
          </section>
          <section id='section4' className="section mt-sm-5 container" data-anchor='fourPage'>
            <div className="row">
              <p className="col-12 mt-3 mt-sm-4 featuresTittel">TESTIMONIALS</p>
            </div>
            <div className="row mb-5">
              <p className="col-12 featuresSubTittel">Trusted by leading companies around the globe</p>
            </div>
            <div className="container desktop">
              <div className="">
                <div className="row ">
                  <div className="col-sm-1">
                  </div>
                  <div className="col col-3 carousel-Div  mr-sm-4">
                    <img className="carouselImages p-2" src={Charles} alt="Logo" />
                    <p className="testtamonialtext">"Ascension is a trusted partner who we depend upon to support our operations in Brisbane,
                          Sydney, UK and Ireland".</p>
                    <span className="TestimonialsName">Charles Slaughter</span>
                    <p className="TestimonialsPost">CEO</p>
                  </div>
                  <div className="col col-3 carousel-Div mr-sm-4">
                    <img className="carouselImages p-2" src={Fabian} alt="Logo" />
                    <p className="testtamonialtext">"Ascension IT’s CloudShed platform has helped us to expand our IT
                    capability through the adoption of cloud technologies." </p>
                    <span className="TestimonialsName">Fabian Carniel</span>
                    <p className="TestimonialsPost">CEO</p>
                  </div>
                  <div className="col col-3 carousel-Div">
                    <img className="carouselImages p-2" src={Dion} alt="Logo" />
                    <p className="testtamonialtext">"The CloudShed was so flexible and provided what I needed,
                          I was able to start multiple ecommerce projects concurrently" </p>
                    <span className="TestimonialsName">Dion Changuion</span>
                    <p className="TestimonialsPost">Owner</p>
                  </div>
                  <div className="col-sm-1">
                  </div>
                </div>
              </div>
            </div>
            <div id="carouselExampleIndicatorss" className="carousel slide mobile carousel-fade" data-ride="carousel">
              <div className="carousel-inner ">
                <div className="item carousel-item active">
                  <div className="col col-sm-3 carousel-Div ">
                    <img className="carouselImages p-2" src={Charles} alt="Logo" />
                    <p className="">"Ascension is a trusted partner who we depend upon to support our operations in Brisbane,
                          Sydney, UK and Ireland".</p>
                    <span className="TestimonialsName">Charles Slaughter</span>
                    <p className="TestimonialsPost">CEO</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col col-sm-3 carousel-Div ">
                    <img className="carouselImages p-2" src={Fabian} alt="Logo" />
                    <p className="">"Ascension IT’s CloudShed platform has helped us to expand our IT
                    capability through the adoption of cloud technologies." </p>
                    <span className="TestimonialsName">Fabian Carniel</span>
                    <p className="TestimonialsPost">CEO</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="col col-sm-3 carousel-Div">
                    <img className="carouselImages p-2" src={Dion} alt="Logo" />
                    <p className="">"The CloudShed was so flexible and provided what I needed,
                          I was able to start multiple  e-commerce projects concurrently" </p>
                    <span className="TestimonialsName">Dion Changuion</span>
                    <p className="TestimonialsPost">Owner</p>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicatorss" role="button" data-slide="prev" aria-hidden="false"><span style={{ color: "black" }} className="fa fa-angle-left leftArrowtwo"></span></a>
              <ol class="carousel-indicators mt-5">
                <li data-target="#carouselExampleIndicatorss" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicatorss" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicatorss" data-slide-to="2"></li>
                <a class="carousel-control-next" href="#carouselExampleIndicatorss" role="button" data-slide="next" aria-hidden="true"><i style={{ color: "black" }} className="fa fa-angle-right"></i></a>
              </ol>
            </div>
          </section>
          <div className="homeFooter">
            <Footer ></Footer>
          </div>
        </div>
      </div>
    )
  };
};