import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import './../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoWhite from './../../images/logoWhite.svg';
import aws from './../../images/aws.svg';
import azure from './../../images/Azure.svg';
import google from './../../images/google.png';
import facebook from './../../images/facebook.png';
import twitter from './../../images/twitter.png';
import linkedin from './../../images/linkeden.png';
import phone from './../../images/phone_icon.svg';
import mail from './../../images/mail_icon.svg';
import tic from './../../images/tic.svg';
import error from './../../images/error (2).svg';
import youtube from './../../images/youtube.svg';
import instagram from './../../images/instagram.svg';
import emailjs from 'emailjs-com';

import Iframe from 'react-iframe'
import {config} from '../../config/config'

export class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      emailValidateshow:"none",
      phoneNo:'',
      showsuceess:false,
      showError:false

    }
  }

 
  handleCloseSuccess = () => {
    this.setState({
      showsuceess: false
    })
  }
  handleCloseError = () => {
    this.setState({
      showError: false
    })
  }

  handleClose = () => {
    this.setState({
      show: false
    })
  }


  handleShow = () => {
    this.setState({
      show: true
    })
  }
  // handleBlurEvent = event => {
   
  //   var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  //   if (event.target.value.match(email_regex)) {
  //     this.setState({
  //       emailValidateshow: "none"
  //     })
  //   }else{
  //     this.setState({
  //       emailValidateshow: "block"
  //     })
  //   }
  // }
//   onChange= event =>{
//     const re = /^[0-9\b]+$/;
//     if (event.target.value === '' || re.test(event.target.value)) {
//        this.setState({phoneNo: event.target.value})
//     }
//  }
  // onSubmit = (e) => {
   
  //   if (this.state.emailValidateshow == "block") {
      
  //   }else{
  //   e.preventDefault()// Prevents default refresh by the browser
  //   emailjs.sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
  //     .then(result => {
  //       this.handleClose();
  //       this.setState({
  //         showsuceess: true
  //       })

  //       // alert('Message Sent, I\'ll get back to you shortly', result.text);
  //     },
  //       error => {
  //         this.handleClose();
  //         this.setState({
  //           showError: true
  //         })
  //         // alert('An error occured, Plese try again', error.text)
  //       })

  //   }
  // }

  render() {
    let url="/aboutus"
    const { email,phoneNo}=this.state;
    return (
      <div className="footer ml-0">
        <>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={this.state.show} onHide={this.handleClose}>
            <Modal.Body><div className="p-2">
              <div>
                <p  onClick={this.handleClose} className="ModalCancel">Close</p>
              <Iframe url={config.url}
              width="100%"
              height="680px"
              id="myId"
              className="myClassname"
              display="initial"
              frameBorder="0"
              position="relative"/>
              
              </div>
           
              
            </div></Modal.Body>
          </Modal>
        </>
        <>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={this.state.showw} onHide={this.handleClose}>
            <Modal.Body><div className="p-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <img className="modelIcon" src={phone} alt="Logo" />
                    <p className="contactDetails">+61 7 3051 5815</p>
                  </div>
                  <div className="col-sm-4">
                    <img className="modelIcon" src={mail} alt="Logo" />
                    <p className="contactDetails">hello@ascensionit.com.au</p>
                  </div>
                  <div className="col-sm-4">
                    <img className="modelIcon" src={phone} alt="Logo" />
                    <p className="contactDetails">+9411 592 7640</p>
                  </div>
                </div>
              </div>
              <div className="container mt-2">
                <div className="row">
                  <p className="ModelTittle">We are happy to hear from you</p>
                  <p className="ModelsubTittle">Please fillout the form and get in touch with us</p>
                </div>
              </div>
              <div className="container">
                <form className="form" onSubmit={this.onSubmit}>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="fullname" tabIndex="0" className="" placeholder="Your full name" type="text" required />
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="email"  tabIndex="0" id="email" value={email} placeholder="Your email address" onBlur={this.handleBlurEvent} type="email" required />
                      <div class="emailValidate" style={{display:this.state.emailValidateshow}}>
                               Please input valid email ID
                      </div>
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <input name="phone"  tabIndex="0" id="phoneNo" value={phoneNo} onChange={this.onChange} placeholder="A number where we can reach out" type="tel" required />
                    </div>
                  </div>
                  <div className="row ml-sm-5 mt-4">
                    <div className="col-12">
                      <textarea name="message"  tabIndex="0" placeholder="The message you want us to read about" type="text"  />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 ">
                    <button type="submit" className="BtnSendMessage mt-5">Send Message </button>
                    </div>
                    <div className="col-6 ">
                    <p onClick={this.handleClose} className="CancelBtn">Cancel</p>
                    </div>
                   
                  </div>
                </form>
              </div>

            </div></Modal.Body>
          </Modal>
        </>
        <>
          <Modal show={this.state.showsuceess} onHide={this.handleCloseSuccess}>
            <Modal.Body>
              <div className="container p-4">
              <img className="modelIcon" src={tic} alt="Logo" />
                <p className="thankyou">Thank You!</p>
                <p className="thankyousub" >Your message was successfully delivered.</p>
                <button className="thankyouBtn" onClick={this.handleCloseSuccess}>
                  Close
          </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
        <>
          <Modal show={this.state.showError} onHide={this.handleCloseError}>
            <Modal.Body>
              <div className="container p-4">
                <img className="modelIcon" src={error} alt="Logo" />
                <p className="thankyou">Sorry! Try Again</p>
                <p className="thankyousub" >Your message couldn’t be delivered.</p>
                <button className="thankyouBtn" onClick={this.handleCloseError}>
                  Close
          </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
        <div className="container">
          <div className="row">
            <div className="col-sm-5 ">

              <img className="mt-5  d-flex justify-content-center footerLogo" src={LogoWhite} alt="Logo" />
              <span className="d-flex justify-content-center footerText">Delivering an unique platform that empowers 
              and transform providers in a holistic way to be ahead of the competition and to emerge triumphant in 
              the digital economy. </span>
              <div className="row mt-4">
                <div className="col-3">
               
                  <img className="footerImg" src={azure} alt="Logo" />
                </div>
                <div className="col-2">
                  <img className="footerImg" src={aws} alt="Logo" />
                </div>
                <div className="col-3">
                  <img className="footerImgGoogle imgalingment" src={google} alt="Logo" />
                </div>
              </div>
              <p className="text-light mt-4 copyrightText desktop">© 2021 Ascension IT Pvt Ltd. All Rights Reserved.</p>
            </div>
            <div className="col-sm-2 campany">
              <p className="text-light mt-5 font-weight-bold">COMPANY</p>
              <div className="row">

                <a  href="/aboutus" className="text-light col-12 mt-1" > About Us</a>
              </div>
              <div className="row">
                <a href="#" style={{cursor:"pointer"}} className="text-light col-12 mt-1 "  onClick={this.handleShow}> Contact Us</a>
              </div>
              <div className="row">
                <a href="/privacy-policy" className="text-light col-12 mt-1" > Privacy Policy</a>
              </div>
              <div className="row">
                <a className="text-light col-12 mt-1 " href="/terms"> Terms</a>
              </div>
            </div>
            <div className="col-sm-3 location">
              <p className="text-light mt-5 font-weight-bold">LOCATIONS</p>
              <div className="row">
                <span className="text-light col-12 mt-1" > Level 54,
                111 Eagle Street
                Brisbane, QLD 4000
            Australia.</span>
                <div className="row mt-2">
                  <span className="text-light ml-3 col-12 mt-1">434 Thimbirigasyaya Road, Colombo 05,
              Sri Lanka.</span>
                </div>
              </div>
            </div>
            <div className="col-sm-2 Connect">
              <p className="text-light mt-5 font-weight-bold">CONNECT</p>
              <div className="row mt-3">
                <img className="col-12 socialIcon " src={linkedin} alt="Logo" /><a className="text-light connect" href="https://www.linkedin.com/company/ascensionit" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </div>
              <div className="row mt-3">
                <img className="col-12 socialIcon" src={facebook} alt="Logo" /><a className="text-light connect" href="https://www.facebook.com/ascensionit.com.au/" target="_blank" rel="noopener noreferrer">Facebook</a>
              </div>
              <div className="row mt-3">
                <img className="col-12 socialIcon " src={twitter} alt="Logo" /><a className="text-light connect" href="https://twitter.com/ascension_it" target="_blank" rel="noopener noreferrer">Twitter</a>
              </div>
              <div className="row mt-3">
                <img className="col-12 socialIcon " src={instagram} alt="Logo" /><a className="text-light connect" href="https://www.instagram.com/ascensionit.com.au/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>
              <div className="row mt-3">
                <img className="col-12 socialIcon " src={youtube} alt="Logo" /><a className="text-light connect" href="https://www.youtube.com/channel/UCB1GXxFqpTd3Ld8w8fbYBqQ" target="_blank" rel="noopener noreferrer">Youtube</a>
              </div>
            </div>
            <div className="mobile container">
              <p className="text-light mt-4 copyrightText">© 2021 Ascension IT Pvt Ltd. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>

    )
  };
};