import React from 'react';
import './../../App.css';
import Logo from './../../images/logo.svg'
import 'bootstrap/dist/css/bootstrap.min.css';

export class Header extends React.Component {
 
  render() {
    return (
      <div className="">
      <div className="row ">
        <div className="col-sm-12">
         <a href="/"> <img   className="headerLogo" src={Logo} alt="Logo" /></a>
        </div>
      </div>
      </div>
    )
  };
};