import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { Home } from './components/home';
import { AboutUs } from './components/aboutus';
import { Policy } from './components/policy';
import { Terms } from './components/terms';
import { Header } from './components/Layout/header';

function App() {

  return (

    // <div id="fullpage" className="App" >
    <div id="" className="App" >
        <Switch>
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/privacy-policy" component={Policy} />
        <Route exact path="/terms" component={Terms} />
        <Route  path="" component={Home} />
        </Switch>
     
    </div>
  );
};
export default App;
