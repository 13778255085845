import React from 'react';
import './../App.css';
// import aboutus from './../images/aboutus.png';
import aboutus from './../images/aboutus.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Janaka from './../images/team/janaka.png';
import Sachintha from './../images/team/sachintha.png';
import Thiwanka from './../images/team/thiwanka.png';
import Dulani from './../images/team/dulani.png';
import Vishwa from './../images/team/vishwa.png';
import Sandun from './../images/team/sandun.png';
import Avon from './../images/team/evon.png';
import Geethan from './../images/team/geethan.png';
import Rashitha from './../images/team/rashitha.png';
import dilakshi from './../images/team/dilakshi.png';
import Dimuth from './../images/team/dimuth.png';
import Sudam from './../images/team/sudam.png';
import No from './../images/team/no.png';
import mimgno from './../images/team mobile/mimgno.png';
import mjanaka from './../images/team mobile/mjanaka.png';
import mthiwanka from './../images/team mobile/mthiwanka.png';
import msachintha from './../images/team mobile/msachintha.png';
import mvishwa from './../images/team mobile/mvishwa.png';
import mdulani from './../images/team mobile/mdulani.png';
import mavon from './../images/team mobile/mavon.png';
import msandun from './../images/team mobile/msandun.png';
import mno from './../images/team mobile/mimgno.png';
import mdilakshi from './../images/team mobile/mdilakshi.png';
import mgeethan from './../images/team mobile/mgeethan.png';
import mrashitha from './../images/team mobile/mrashitha.png';
import mdimuth from './../images/team mobile/mdimuth.png';
import msudam from './../images/team mobile/msudam.png';
import img_1 from './../images/img_1.png';

import img_5 from './../images/img_5.png';
import img_6 from './../images/img_6.png';

import { Footer } from './Layout/footer';
import { Header } from './Layout/header';


export class AboutUs extends React.Component {
 
    render() {
        return (
            <div className="">
                <section id='' className=" container">
                    <div className="col-12">
                        <Header ></Header>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-sm-5">
                            <p className="TopText mobileTop"><b>Our</b> Company</p>
                            <p className="TopSubTextAboutUs"> <img className="banner bannerAbout" src={aboutus} alt="Logo" />Ascension IT, was founded in 2014 by Dimuth Samaranayaka and Janaka Wickremasinghe who
                            identified a skill gap developing in the market around the <strong>‘art of performance optimisation and automation’. </strong>
                            They also identified the need to bring together the wealth of consulting expertise and best practices
                            in data and server management, security and stabilization including operational analytics. <br /><br />
                            CloudShed&trade; Platform had evolved from an <strong>optimization &amp; automation platform</strong>, <strong>multi-cloud service brokerage</strong>,&nbsp;
                            <strong>analytics &amp; intelligent engine</strong> to <strong>SaaSification &amp; commercialization engine</strong> and it pack enough batteries to&nbsp;
                            <strong>empower</strong> and <strong>transform</strong> providers to <strong>win</strong> the Digital Economy.</p>
                        </div>
                        {/* <div className="col-sm-6 mt-3">
                            <img className="banner bannerAbout" src={aboutus} alt="Logo" />
                        </div> */}
                    </div>
                </section>
                <section id='' className=" mt-5 container" >
                    <div className="row ">
                        <div className="col-sm-12">
                            <p className="seconSecTittle">Our Team</p>
                            {/* <p className="seconSecSub">We have been actively working in the market with some of our key customers to
                            develop a suite of applications targeted to deliver value and address business challenges on a day to day environment.
                            Our intent is to replicate the success we have been able to impart on our customers to other organisations
                               to become competitive in the markets they are operating in.</p> */}
                        </div>

                        <div className="col-sm-12 mt-sm-5 employeImg desktop">
                            <div className="row ml-sm-2">
                                <div className="col-sm-3">
                                    <img className="photoes" src={Dimuth} alt="teamPic" />
                                    <p className="Ename">Dimuth Samaranayaka</p>
                                    <p className="Etittle">CEO &amp; CTO / Co-Founder</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Janaka} alt="teamPic" />
                                    <p className="Ename">Janaka Wickramasighe</p>
                                    <p className="Etittle">Director / Co-Founder</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Thiwanka} alt="teamPic" />
                                    <p className="Ename">Thiwanka Nawalage</p>
                                    <p className="Etittle">Project Manager</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Sachintha} alt="teamPic" />
                                    <p className="Ename">Sachintha Muthukumarana</p>
                                    <p className="Etittle">Lead Software Engineer</p>
                                </div>
                            </div>
                            <div className="row mt-4 ml-sm-2">
                                <div className="col-sm-3">
                                    <img className="photoes" src={Vishwa} alt="teamPic" />
                                    <p className="Ename">Vishva Ratnayake</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Dulani} alt="teamPic" />
                                    <p className="Ename">Dulani Herath</p>
                                    <p className="Etittle">Business Development Executive</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Avon} alt="teamPic" />
                                    <p className="Ename">Avon Pereira</p>
                                    <p className="Etittle">UI/UX Designer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Sandun} alt="teamPic" />
                                    <p className="Ename">Sandun Thilakarathna</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                            </div>
                            <div className="row  ml-sm-2 mt-sm-4">
                                <div className="col-sm-3">
                                    <img className="photoes" src={Geethan} alt="teamPic" />
                                    <p className="Ename">Chanaka Wellehewa</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Rashitha} alt="teamPic" />
                                    <p className="Ename">Rashitha Batugedara</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Sudam} alt="teamPic" />
                                    <p className="Ename">Sudam Mahagamage</p>
                                    <p className="Etittle">DevOps Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={dilakshi} alt="teamPic" />
                                    <p className="Ename">Dilakshi Amarasinge</p>
                                    <p className="Etittle">Admin Support Officer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="row">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mdimuth} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Dimuth Samaranayaka</p>
                                        <p className="Etittle">CEO &amp; CTO / Co-Founder</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mjanaka} alt="teamPic" />
                                    </div>
                                    <div className="col-12">

                                        <p className="Ename">Janaka Wickramasighe</p>
                                        <p className="Etittle">Director / Co-Founder</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mthiwanka} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Thiwanka Nawalage</p>
                                        <p className="Etittle">Project Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={msachintha} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Sachintha Muthukumarana</p>
                                        <p className="Etittle">Lead Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mvishwa} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Vishva Ratnayake</p>
                                        <p className="Etittle">Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mdulani} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Dulani Herath</p>
                                        <p className="Etittle">Business Development Executive</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mavon} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Avon Pereira</p>
                                        <p className="Etittle">UI/UX Designer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={msandun} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Sandun Thilakarathna</p>
                                        <p className="Etittle">Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mgeethan} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Chanaka Wellehewa</p>
                                        <p className="Etittle">Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mrashitha} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Rashitha Batugedara</p>
                                        <p className="Etittle">Software Engineer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={msudam} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Sudam Mahagamage</p>
                                        <p className="Etittle">DevOps Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="photoes" src={mdilakshi} alt="teamPic" />
                                    </div>
                                    <div className="col-12">
                                        <p className="Ename">Dilakshi Amarasinge</p>
                                        <p className="Etittle">Admin Support Officer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='' className=" mt-sm-5 container" >
                    <div className="row">
                        {/* <div className="col-sm-12 employeImg desktop">
                            <div className="row  ml-sm-2 ">
                                <div className="col-sm-3">
                                    <img className="photoes" src={Geethan} alt="teamPic" />
                                    <p className="Ename">Chanaka Wellehewa</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={Rashitha} alt="teamPic" />
                                    <p className="Ename">Rashitha Batugedara</p>
                                    <p className="Etittle">Software Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={No} alt="teamPic" />
                                    <p className="Ename">Sudam Mahagamage</p>
                                    <p className="Etittle">DevOps Engineer</p>
                                </div>
                                <div className="col-sm-3">
                                    <img className="photoes" src={dilakshi} alt="teamPic" />
                                    <p className="Ename">Dilakshi Amarasinge</p>
                                    <p className="Etittle">Admin Support Officer</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
                <section id='' className="mt-sm-5" >
                    <div className="">
                        <Footer></Footer>
                    </div>
                </section>
            </div >
        )
    };
};